import { useVideoTexture } from "@react-three/drei";
import { useEffect, useMemo } from "react";
import * as THREE from "three";
export default function VideoMaterial({ src }: { src: string }) {
  const texture = useVideoTexture(src);

  const fragmentShader = `
  uniform vec3 keyColor;
  uniform float similarity;
  uniform float smoothness;
  varying vec2 vUv;
  uniform sampler2D map;
  void main() {

      vec4 videoColor = texture2D(map, vUv);

      float Y1 = 0.299 * keyColor.r + 0.587 * keyColor.g + 0.114 * keyColor.b;
      float Cr1 = keyColor.r - Y1;
      float Cb1 = keyColor.b - Y1;
      
      float Y2 = 0.299 * videoColor.r + 0.587 * videoColor.g + 0.114 * videoColor.b;
      float Cr2 = videoColor.r - Y2; 
      float Cb2 = videoColor.b - Y2; 
      
      float blend = smoothstep(similarity, similarity + smoothness, distance(vec2(Cr2, Cb2), vec2(Cr1, Cb1)));
      gl_FragColor = vec4(videoColor.rgb * 2.0, videoColor.a * blend) ; 
  }`;
  const vertexShader = `
  varying vec2 vUv;
  void main( void ) {     
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
  }`;

  const uniforms = useMemo(
    () => ({
      map: { value: texture },
      keyColor: { value: new THREE.Color(0x00ef00) },
      similarity: { value: 0.25 },
      smoothness: { value: 0.5 },
    }),
    []
  );

  useEffect(() => {
    texture.repeat = new THREE.Vector2(9, 1);
    texture.offset = new THREE.Vector2(0.1, 0);
    console.log(texture.offset);
  });

  return (
    <shaderMaterial
      fragmentShader={fragmentShader}
      vertexShader={vertexShader}
      uniforms={uniforms}
      toneMapped={false}
      depthTest={false}
      transparent={true}
    />
  );
}
