import selfie from "./selfie.jpg";
import useAppStore, { NavState } from "../useAppStore";
/* on button tapped, open the browser Share API with selfie.jpg */
async function shareSelfie() {
  useAppStore.setState({ navState: NavState.PHOTOSHARE });
  if (navigator.share) {
    const response = await fetch(selfie);
    const blob = await response.blob();
    const file = new File([blob], "selfie.jpg", { type: "image/jpeg" });
    navigator
      .share({
        title: "Selfie",
        text: "I took a selfie!",
        files: [file],
      })
      .then(() => {
        useAppStore.setState({ navState: NavState.END });
      });
  }
}

export default function PhotoButton() {
  const size = "6rem";
  return (
    <div
      onClick={shareSelfie}
      style={{
        position: "absolute",
        bottom: "3rem",
        height: size,
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: size,
          height: size,
          borderRadius: "50%",
          backgroundColor: "white",
          zIndex: 5000,
        }}
      ></div>
    </div>
  );
}
