import React, { useEffect } from "react";
import "./App.css";
import SceneRoot from "./components/SceneRoot";
import useAppStore, { NavState } from "./useAppStore";
import PhotoButton from "./components/PhotoButton";
import SelectPlayers from "./components/SelectPlayers";

function App() {
  const navState = useAppStore((state) => state.navState);
  useEffect(() => {
    if (navState === NavState.NONE) {
      useAppStore.setState({ navState: NavState.HOME });
    }
  }, [navState]);

  const goToPlayerSelect = () => {
    useAppStore.setState({ navState: NavState.SELECTPLAYERS });
  };

  return (
    <div className="App">
      {navState === NavState.HOME && (
        <>
          <h1>Home</h1>
          <div
            style={{
              padding: "1rem 2rem",
              background: "grey",
              color: "white",
              width: "20vw",

              margin: "auto",
              marginTop: "8rem",
            }}
            onClick={() => goToPlayerSelect()}
          >
            Start
          </div>
        </>
      )}
      {navState === NavState.DESKTOP && <h1>Desktop</h1>}
      {navState === NavState.SELECTPLAYERS && <SelectPlayers />}
      {navState === NavState.SELFIE && (
        <>
          <SceneRoot />
          <PhotoButton />
        </>
      )}
      {navState === NavState.PHOTOSHARE && <h1>Photoshare</h1>}
      {navState === NavState.END && <h1>End</h1>}
    </div>
  );
}

export default App;
