import { Suspense, useEffect, useRef } from "react";
import VideoMaterial from "./VideoMaterial";
import * as THREE from "three";
export default function VideoPlane({
  size,
  position,
  src,
}: {
  size: [number, number, number];
  position: [number, number, number];
  src: string;
}) {
  const planeRef = useRef<any>();

  return (
    <mesh
      scale={[1.6 * size[0], 0.9 * size[1], size[2]]}
      rotation={[0, 0, 0]}
      position={position}
      ref={planeRef}
    >
      <planeGeometry />

      <VideoMaterial src={src} />
    </mesh>
  );
}
