import { Canvas, useFrame } from "@react-three/fiber";
import XRScene from "./XRScene";
import Segmentation from "./Segmentation";
import { DeviceOrientationControls } from "@react-three/drei";

import { extend } from "@react-three/fiber";

export default function SceneRoot() {
  return (
    <>
      <Segmentation />
      <Canvas>
        <XRScene />
      </Canvas>
    </>
  );
}
