import { useFrame } from "@react-three/fiber";
import VideoPlane from "./VideoPlane";
import * as THREE from "three";
import { useEffect } from "react";
let alpha = 0;
let beta = 0;
let alphaOffset = 0;
let gamma = 0;
let deviceOrientation: DeviceOrientationEvent | null = null;
let screenOrientation = 0;
const EPS = 0.000001;
const lastQuaternion = new THREE.Quaternion();

window.addEventListener(
  "deviceorientation",
  function (e: DeviceOrientationEvent) {
    deviceOrientation = e;
  }.bind(this)
);

const onScreenOrientationChangeEvent = function () {
  screenOrientation = window.orientation || 0;
};

window.addEventListener(
  "orientationchange",
  onScreenOrientationChangeEvent.bind(this)
);

const setObjectQuaternion = (function () {
  const zee = new THREE.Vector3(0, 0, 1);
  const euler = new THREE.Euler();
  const q0 = new THREE.Quaternion();
  const q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis
  return function (
    quaternion: THREE.Quaternion,
    alpha: number,
    beta: number,
    gamma: number,
    orient: number
  ) {
    euler.set(beta, alpha, -gamma, "YXZ"); // 'ZXY' for the device, but 'YXZ' for us
    quaternion.setFromEuler(euler); // orient the device
    quaternion.multiply(q1); // camera looks out the back of the device, not the top
    quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
    // Mirror the quaternion across the XY plane to invert the pitch
    quaternion.x *= -1;
    quaternion.y *= -1;
  };
})();

export default function XRScene() {
  useEffect(() => {
    onScreenOrientationChangeEvent();
  }, []);
  //extend({ DeviceOrientationControls });
  useFrame((state) => {
    const device = deviceOrientation;
    if (device) {
      const alpha = device.alpha
        ? THREE.MathUtils.degToRad(device.alpha) + alphaOffset
        : 0; // Z

      const beta = device.beta ? THREE.MathUtils.degToRad(device.beta) : 0; // X'

      const gamma = device.gamma ? THREE.MathUtils.degToRad(device.gamma) : 0; // Y''

      const orient = screenOrientation
        ? THREE.MathUtils.degToRad(screenOrientation)
        : 0; // O

      setObjectQuaternion(state.camera.quaternion, alpha, beta, gamma, orient);

      if (8 * (1 - lastQuaternion.dot(state.camera.quaternion)) > EPS) {
        lastQuaternion.copy(state.camera.quaternion);
        //scope.dispatchEvent(_changeEvent);
      }
    }
  });
  return (
    <group>
      <VideoPlane src="A.mp4" size={[7, 7, 7]} position={[-0.15, -1.95, 3.0]} />
      <VideoPlane src="C.mp4" size={[7, 7, 7]} position={[0.78, -1.95, 3.0]} />
      <VideoPlane src="B.mp4" size={[-7, 7, 7]} position={[-0.33, -1.3, 1.2]} />
    </group>
  );
}
