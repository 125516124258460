import { useState } from "react";
import useAppStore, { NavState } from "../useAppStore";

export default function SelectPlayers() {
  const [selected, setSelected] = useState<number[]>([]);

  const handleClick = (id: number) => {
    let newSelected: number[] = [...selected];
    if (newSelected.includes(id)) {
      newSelected = newSelected.filter((item) => item !== id);
    } else if (newSelected.length < 3) {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const startExperience = () => {
    if (
      typeof DeviceOrientationEvent !== "undefined" &&
      //@ts-ignore
      typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
      //@ts-ignore
      DeviceOrientationEvent.requestPermission()
        .then((permissionState: any) => {
          if (permissionState === "granted") {
            console.log("iOS 13+ orientation granted");
            useAppStore.setState({ navState: NavState.SELFIE });
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
      console.log("not iOS");
      useAppStore.setState({ navState: NavState.SELFIE });
    }
  };

  return (
    <>
      <h1>Select 3 Players</h1>
      <div className="grid">
        {Array.from({ length: 9 }).map((_, id) => (
          <div
            key={id}
            className={`grid-item ${selected.includes(id) ? "selected" : ""}`}
            onClick={() => handleClick(id)}
          />
        ))}
      </div>
      <button
        className="accept-button"
        onClick={startExperience}
        disabled={selected.length !== 3}
      >
        Accept
      </button>
    </>
  );
}
