import React, { useEffect, useRef } from "react";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import useAppStore, { NavState } from "../useAppStore";

// TODO - needs scaling work,  smoothing, and indpendent mask/vid rez

function Segmentation() {
  let rAF = -1;
  const inputVideoRef = useRef<any>();
  const canvasRef = useRef<any>();
  const contextRef = useRef<any>();
  const workCanvasRef = useRef<HTMLCanvasElement>(
    document.createElement("canvas")
  );
  const selfieMode = useAppStore((state) => state.selfieMode);
  const toggleSelfieMode = useAppStore((state) => state.toggleSelfieMode);

  const workContextRef = useRef<any>(workCanvasRef.current.getContext("2d"));

  let selfieSegmentation: SelfieSegmentation | null = null;

  const onResults = (results: any) => {
    if (!results.segmentationMask) return;
    if (!workCanvasRef.current) return;
    if (!workContextRef.current) return;
    if (!contextRef.current) return;
    if (!canvasRef.current) return;
    if (!inputVideoRef.current) return;

    workContextRef.current!.willReadFrequently = true;
    contextRef.current!.willReadFrequently = true;
    // Clear the working canvas
    workContextRef.current!.clearRect(
      0,
      0,
      workCanvasRef.current!.width,
      workCanvasRef.current!.height
    );

    // Draw the segmentation mask to the working canvas
    workContextRef.current!.drawImage(
      results.segmentationMask,
      0,
      0,
      workCanvasRef.current!.width,
      workCanvasRef.current!.height
    );

    // Get the image data from the segmentation mask
    let maskImageData = workContextRef.current!.getImageData(
      0,
      0,
      workCanvasRef.current!.width,
      workCanvasRef.current!.height
    );

    // Draw the original image to the working canvas
    workContextRef.current!.drawImage(
      results.image,
      0,
      0,
      workCanvasRef.current!.width,
      workCanvasRef.current!.height
    );

    // Get the image data from the original image
    let originalImageData = workContextRef.current!.getImageData(
      0,
      0,
      workCanvasRef.current!.width,
      workCanvasRef.current!.height
    );

    // Loop over each pixel in the original image data
    for (let i = 0; i < originalImageData.data.length; i += 4) {
      // Use the corresponding pixel in the mask to set the alpha channel
      originalImageData.data[i + 3] = maskImageData.data[i];
    }

    // Put the updated original image data back onto the working canvas
    workContextRef.current!.putImageData(originalImageData, 0, 0);

    // Clear the display canvas
    contextRef.current!.clearRect(
      0,
      0,
      canvasRef.current!.width,
      canvasRef.current!.height
    );

    // Draw the updated original image from the working canvas to the display canvas
    contextRef.current!.drawImage(workCanvasRef.current, 0, 0);
  };

  useEffect(() => {
    if (selfieSegmentation != null) return;
    contextRef.current = canvasRef.current!.getContext("2d")!;
    workCanvasRef.current.width = canvasRef.current!.width;
    workCanvasRef.current.height = canvasRef.current!.height;

    const constraints = {
      video: { width: { max: 1024 }, height: { max: 512 } },
      facingMode: "user",
      audio: false,
    };

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      inputVideoRef.current!.srcObject = stream;
      sendToMediaPipe();
    });

    console.log("loading selfie segmentation");
    selfieSegmentation = new SelfieSegmentation({
      locateFile: (file: any) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`,
    });

    selfieSegmentation.setOptions({
      modelSelection: 1,
      selfieMode: true,
    });

    //selfieSegmentation.initialize();

    selfieSegmentation.onResults(onResults);

    const sendToMediaPipe = async () => {
      if (!inputVideoRef.current || !inputVideoRef.current.videoWidth) {
        rAF = requestAnimationFrame(sendToMediaPipe);
      } else {
        if (canvasRef.current!.width !== inputVideoRef.current!.videoWidth) {
          canvasRef.current!.width = inputVideoRef.current!.videoWidth;
          canvasRef.current!.height = inputVideoRef.current!.videoHeight;
          workCanvasRef.current.width = canvasRef.current!.width;
          workCanvasRef.current.height = canvasRef.current!.height;
        }

        await selfieSegmentation!.send({ image: inputVideoRef.current! });
        rAF = requestAnimationFrame(sendToMediaPipe);
      }
    };
    return () => {
      //selfieSegmentation!.close();
      // selfieSegmentation = null;
      cancelAnimationFrame(rAF);
    };
  }, []);
  /*
  useEffect(() => {
    const constraints = {
      video: { width: { max: 1024 }, height: { max: 512 } },
      facingMode: selfieMode ? "user" : "environment",
      audio: false,
    };
    console.log(constraints.facingMode);
    inputVideoRef.current!.srcObject = null;
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      inputVideoRef.current!.srcObject = stream;
      inputVideoRef.current.onloadeddata = () => {
        canvasRef.current!.width = inputVideoRef.current!.videoWidth;
        canvasRef.current!.height = inputVideoRef.current!.videoHeight;
        workCanvasRef.current.width = canvasRef.current!.width;
        workCanvasRef.current.height = canvasRef.current!.height;
      };
      console.log("NEW STREAM");
    });
  }, [selfieMode]);
  */

  const outerStyle: any = {
    position: "relative",
    width: "100%",
    height: "100vh",
    zIndex: 0,
  };

  const innerStyle: any = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "100%",
    width: "100%",
    zIndex: 0,
  };

  const videoStyle: any = {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    zIndex: 0,
    transform: "scaleX(-1)",
  };

  const canvasStyle: any = {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    zIndex: 4000,
  };

  const swapStyle: any = {
    position: "absolute",
    top: "12px",
    left: "12px",
    zIndex: 5000,
    color: "white",
    background: "black",
    padding: "0.5rem",
    fontWeight: "bold",
    display: "none",
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      >
        <div style={outerStyle}>
          <div style={innerStyle}>
            <video
              autoPlay
              playsInline
              muted
              ref={inputVideoRef}
              style={videoStyle}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 4000,
        }}
      >
        <div style={outerStyle}>
          <div style={innerStyle}>
            <canvas ref={canvasRef} style={canvasStyle} />
          </div>
        </div>
      </div>
      <div style={swapStyle} onClick={toggleSelfieMode}>
        Swap Cam
      </div>
    </>
  );
}

export default Segmentation;
