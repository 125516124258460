import { create } from "zustand";
export enum NavState {
  NONE,
  HOME,
  DESKTOP,
  SELECTPLAYERS,
  SELFIE,
  PHOTOSHARE,
  END,
}

type AppState = {
  navState: NavState;
  selfieMode: boolean;
  removeAllBears: () => void;
};

const useAppStore = create((set: any) => ({
  navState: NavState.NONE,
  selfieMode: true,
  setNavState: (state: NavState) =>
    set((state: AppState) => ({ navState: state })),
  toggleSelfieMode: () =>
    set((state: AppState) => ({ selfieMode: !state.selfieMode })),
  removeAllBears: () => set({ bears: 0 }),
}));

export default useAppStore;
